import { defineAsyncComponent } from 'vue'
export default {
  base: defineAsyncComponent(() => import("/opt/build/repo/apps/spa/layouts/base.vue").then(m => m.default || m)),
  dashboard: defineAsyncComponent(() => import("/opt/build/repo/apps/spa/layouts/dashboard.vue").then(m => m.default || m)),
  focused: defineAsyncComponent(() => import("/opt/build/repo/apps/spa/layouts/focused.vue").then(m => m.default || m)),
  "focused-white": defineAsyncComponent(() => import("/opt/build/repo/apps/spa/layouts/focusedWhite.vue").then(m => m.default || m)),
  "focused-wider": defineAsyncComponent(() => import("/opt/build/repo/apps/spa/layouts/focusedWider.vue").then(m => m.default || m)),
  "freeflexer-focus": defineAsyncComponent(() => import("/opt/build/repo/apps/spa/layouts/freeflexer-focus.vue").then(m => m.default || m)),
  "freeflexer-full-width": defineAsyncComponent(() => import("/opt/build/repo/apps/spa/layouts/freeflexer-fullWidth.vue").then(m => m.default || m)),
  "freeflexer-onboarding": defineAsyncComponent(() => import("/opt/build/repo/apps/spa/layouts/freeflexer-onboarding.vue").then(m => m.default || m)),
  "full-width": defineAsyncComponent(() => import("/opt/build/repo/apps/spa/layouts/fullWidth.vue").then(m => m.default || m)),
  "help-page": defineAsyncComponent(() => import("/opt/build/repo/apps/spa/layouts/helpPage.vue").then(m => m.default || m)),
  informative: defineAsyncComponent(() => import("/opt/build/repo/apps/spa/layouts/informative.vue").then(m => m.default || m)),
  "login-screen": defineAsyncComponent(() => import("/opt/build/repo/apps/spa/layouts/loginScreen.vue").then(m => m.default || m))
}