
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93asSdIWWerQMeta } from "/opt/build/repo/apps/spa/pages/[...slug].vue?macro=true";
import { default as index82lZJgnB1AMeta } from "/opt/build/repo/apps/spa/pages/404/index.vue?macro=true";
import { default as aanleveren_45belastingdienstKifZhBHV3zMeta } from "/opt/build/repo/apps/spa/pages/aanleveren-belastingdienst.vue?macro=true";
import { default as indexgWu3oR8uVyMeta } from "/opt/build/repo/apps/spa/pages/admin/client/invoices/index.vue?macro=true";
import { default as indexlekkSp2i1WMeta } from "/opt/build/repo/apps/spa/pages/admin/contractor/invoices/index.vue?macro=true";
import { default as callbackwe3WggBFIdMeta } from "/opt/build/repo/apps/spa/pages/auth/callback.vue?macro=true";
import { default as complete_45your_45accountzCfN3PYnCAMeta } from "/opt/build/repo/apps/spa/pages/complete-your-account.vue?macro=true";
import { default as indexD1u7p859cYMeta } from "/opt/build/repo/apps/spa/pages/dashboard/client/invoices/index.vue?macro=true";
import { default as indexCK9LFF530yMeta } from "/opt/build/repo/apps/spa/pages/dashboard/client/jobs/[...slug]/index.vue?macro=true";
import { default as createkvB6nbw4f6Meta } from "/opt/build/repo/apps/spa/pages/dashboard/client/jobs/[...slug]/shifts/create.vue?macro=true";
import { default as new_45job_45template0BHMyaPCEWMeta } from "/opt/build/repo/apps/spa/pages/dashboard/client/jobs/new-job-template.vue?macro=true";
import { default as notification_45settingsaMCng9V71XMeta } from "/opt/build/repo/apps/spa/pages/dashboard/client/notification-settings.vue?macro=true";
import { default as statisticsUWUg2XH4bzMeta } from "/opt/build/repo/apps/spa/pages/dashboard/client/statistics.vue?macro=true";
import { default as indexddd96WxDHzMeta } from "/opt/build/repo/apps/spa/pages/dashboard/contractor/invoices/index.vue?macro=true";
import { default as indexsnYoUl7GCbMeta } from "/opt/build/repo/apps/spa/pages/dashboard/index.vue?macro=true";
import { default as _91_46_46_46slug_93aRm7vHDOZNMeta } from "/opt/build/repo/apps/spa/pages/dashboard/planning/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93U9fWSYuuCfMeta } from "/opt/build/repo/apps/spa/pages/freeflexer/complete-profile/[...slug].vue?macro=true";
import { default as insurancesYtO9sgse3gMeta } from "/opt/build/repo/apps/spa/pages/freeflexer/insurances.vue?macro=true";
import { default as company_45identification_45numberkDfNd7okq3Meta } from "/opt/build/repo/apps/spa/pages/freeflexer/onboarding/company-identification-number.vue?macro=true";
import { default as profileuw0YfPk0w6Meta } from "/opt/build/repo/apps/spa/pages/freeflexer/profile.vue?macro=true";
import { default as indexEcK1QlptJPMeta } from "/opt/build/repo/apps/spa/pages/get-started/index.vue?macro=true";
import { default as indexigIsVyE8j8Meta } from "/opt/build/repo/apps/spa/pages/index.vue?macro=true";
import { default as indexQcmSlNg2CoMeta } from "/opt/build/repo/apps/spa/pages/login/index.vue?macro=true";
import { default as logoutQjushKSqWcMeta } from "/opt/build/repo/apps/spa/pages/logout.vue?macro=true";
import { default as platform_45guideKCFrKkPKufMeta } from "/opt/build/repo/apps/spa/pages/platform-guide.vue?macro=true";
import { default as account_45activated42FlxQ5HWGMeta } from "/opt/build/repo/apps/spa/pages/signup/account-activated.vue?macro=true";
import { default as activate_45accountdWVZ02OVISMeta } from "/opt/build/repo/apps/spa/pages/signup/activate-account.vue?macro=true";
import { default as already_45activatediNvt9EpHgyMeta } from "/opt/build/repo/apps/spa/pages/signup/already-activated.vue?macro=true";
import { default as indexbbgz6IbXwJMeta } from "/opt/build/repo/apps/spa/pages/signup/index.vue?macro=true";
import { default as terms_45and_45conditionsLWFMTxoY0GMeta } from "/opt/build/repo/apps/spa/pages/terms-and-conditions.vue?macro=true";
import { default as component_45stubfv7y3njT1pMeta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_57rknjok6nif4yecs2w4dqozma/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubfv7y3njT1p } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_57rknjok6nif4yecs2w4dqozma/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/apps/spa/pages/[...slug].vue")
  },
  {
    name: "404",
    path: "/404",
    meta: index82lZJgnB1AMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/404/index.vue")
  },
  {
    name: "aanleveren-belastingdienst",
    path: "/aanleveren-belastingdienst",
    meta: aanleveren_45belastingdienstKifZhBHV3zMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/aanleveren-belastingdienst.vue")
  },
  {
    name: "admin-client-invoices",
    path: "/admin/client/invoices",
    meta: indexgWu3oR8uVyMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/admin/client/invoices/index.vue")
  },
  {
    name: "admin-contractor-invoices",
    path: "/admin/contractor/invoices",
    meta: indexlekkSp2i1WMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/admin/contractor/invoices/index.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: callbackwe3WggBFIdMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/auth/callback.vue")
  },
  {
    name: "complete-your-account",
    path: "/complete-your-account",
    meta: complete_45your_45accountzCfN3PYnCAMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/complete-your-account.vue")
  },
  {
    name: "dashboard-client-invoices",
    path: "/dashboard/client/invoices",
    meta: indexD1u7p859cYMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/client/invoices/index.vue")
  },
  {
    name: "dashboard-client-jobs-slug",
    path: "/dashboard/client/jobs/:slug(.*)*",
    meta: indexCK9LFF530yMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/client/jobs/[...slug]/index.vue")
  },
  {
    name: "dashboard-client-jobs-slug-shifts-create",
    path: "/dashboard/client/jobs/:slug(.*)*/shifts/create",
    meta: createkvB6nbw4f6Meta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/client/jobs/[...slug]/shifts/create.vue")
  },
  {
    name: "dashboard-client-jobs-new-job-template",
    path: "/dashboard/client/jobs/new-job-template",
    meta: new_45job_45template0BHMyaPCEWMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/client/jobs/new-job-template.vue")
  },
  {
    name: "dashboard-client-notification-settings",
    path: "/dashboard/client/notification-settings",
    meta: notification_45settingsaMCng9V71XMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/client/notification-settings.vue")
  },
  {
    name: "dashboard-client-statistics",
    path: "/dashboard/client/statistics",
    meta: statisticsUWUg2XH4bzMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/client/statistics.vue")
  },
  {
    name: "dashboard-contractor-invoices",
    path: "/dashboard/contractor/invoices",
    meta: indexddd96WxDHzMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/contractor/invoices/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexsnYoUl7GCbMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-planning-slug",
    path: "/dashboard/planning/:slug(.*)*",
    meta: _91_46_46_46slug_93aRm7vHDOZNMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/planning/[...slug].vue")
  },
  {
    name: "freeflexer-complete-profile-slug",
    path: "/freeflexer/complete-profile/:slug(.*)*",
    meta: _91_46_46_46slug_93U9fWSYuuCfMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/freeflexer/complete-profile/[...slug].vue")
  },
  {
    name: "freeflexer-insurances",
    path: "/freeflexer/insurances",
    meta: insurancesYtO9sgse3gMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/freeflexer/insurances.vue")
  },
  {
    name: "freeflexer-onboarding-company-identification-number",
    path: "/freeflexer/onboarding/company-identification-number",
    meta: company_45identification_45numberkDfNd7okq3Meta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/freeflexer/onboarding/company-identification-number.vue")
  },
  {
    name: "freeflexer-profile",
    path: "/freeflexer/profile",
    meta: profileuw0YfPk0w6Meta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/freeflexer/profile.vue")
  },
  {
    name: "get-started",
    path: "/get-started",
    meta: indexEcK1QlptJPMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/get-started/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexigIsVyE8j8Meta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexQcmSlNg2CoMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/login/index.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutQjushKSqWcMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/logout.vue")
  },
  {
    name: "platform-guide",
    path: "/platform-guide",
    meta: platform_45guideKCFrKkPKufMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/platform-guide.vue")
  },
  {
    name: "signup-account-activated",
    path: "/signup/account-activated",
    meta: account_45activated42FlxQ5HWGMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/signup/account-activated.vue")
  },
  {
    name: "signup-activate-account",
    path: "/signup/activate-account",
    meta: activate_45accountdWVZ02OVISMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/signup/activate-account.vue")
  },
  {
    name: "signup-already-activated",
    path: "/signup/already-activated",
    meta: already_45activatediNvt9EpHgyMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/signup/already-activated.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: indexbbgz6IbXwJMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/signup/index.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    meta: terms_45and_45conditionsLWFMTxoY0GMeta || {},
    component: () => import("/opt/build/repo/apps/spa/pages/terms-and-conditions.vue")
  },
  {
    name: component_45stubfv7y3njT1pMeta?.name,
    path: "/fr-fr/inscription",
    component: component_45stubfv7y3njT1p
  }
]