import revive_payload_client_d6RCJGsjwq from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_57rknjok6nif4yecs2w4dqozma/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5Z0nMkVI33 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_57rknjok6nif4yecs2w4dqozma/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FkJWIEaPsM from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_57rknjok6nif4yecs2w4dqozma/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5r5HcU0rnN from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_57rknjok6nif4yecs2w4dqozma/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_g6oa6h4zmM from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_57rknjok6nif4yecs2w4dqozma/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_vxlViOHepo from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_57rknjok6nif4yecs2w4dqozma/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_sgnSi1FiIZ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_57rknjok6nif4yecs2w4dqozma/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Wdo3N1EWgc from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/apps/spa/.nuxt/components.plugin.mjs";
import prefetch_client_oPxvdOoLgJ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.1_@types+node@22.13.5_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_57rknjok6nif4yecs2w4dqozma/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_fo6iwgo5m4 from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.15.2_@parcel+watcher@2.5.1_@types+node_jebithykjt5jamy7ofkoxbwwou/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_Pa8IWzKB4A from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import http_plugin_nuxt_WB4fjyTbYV from "/opt/build/repo/node_modules/.pnpm/@nuxt-alt+http@1.7.10_magicast@0.3.5_nuxi@3.22.2_typescript@5.7.3/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import auth_plugin_6DDHMgc34l from "/opt/build/repo/apps/spa/.nuxt/auth.plugin.mjs";
import FBPixel_pfxXiHSWff from "/opt/build/repo/apps/spa/plugins/FBPixel.ts";
import apollo_client_VTaW5dp4nd from "/opt/build/repo/apps/spa/plugins/apollo-client.ts";
import changeCountry_q2Al21Mu2F from "/opt/build/repo/apps/spa/plugins/changeCountry.ts";
import hotjar_pBWYs5JQbk from "/opt/build/repo/apps/spa/plugins/hotjar.ts";
import importPlugins_dSrmlJHrsT from "/opt/build/repo/apps/spa/plugins/importPlugins.ts";
import intercom_tYaoKVt49Q from "/opt/build/repo/apps/spa/plugins/intercom.ts";
import mixpanel_vYbGOFriae from "/opt/build/repo/apps/spa/plugins/mixpanel.ts";
import msw_6UYrCvzujF from "/opt/build/repo/apps/spa/plugins/msw.ts";
import navigation_QptjALbk5P from "/opt/build/repo/apps/spa/plugins/navigation.ts";
import setCountry_56dWCNFicO from "/opt/build/repo/apps/spa/plugins/setCountry.ts";
import setUser_7ED3DT1SjL from "/opt/build/repo/apps/spa/plugins/setUser.ts";
import userSnap_JoYSL8axbe from "/opt/build/repo/apps/spa/plugins/userSnap.ts";
import validationFields_ioW4FysPzn from "/opt/build/repo/apps/spa/plugins/validationFields.ts";
export default [
  revive_payload_client_d6RCJGsjwq,
  unhead_5Z0nMkVI33,
  router_FkJWIEaPsM,
  payload_client_5r5HcU0rnN,
  navigation_repaint_client_g6oa6h4zmM,
  check_outdated_build_client_vxlViOHepo,
  chunk_reload_client_sgnSi1FiIZ,
  plugin_vue3_Wdo3N1EWgc,
  components_plugin_KR1HBZs4kY,
  prefetch_client_oPxvdOoLgJ,
  plugin_fo6iwgo5m4,
  i18n_Pa8IWzKB4A,
  http_plugin_nuxt_WB4fjyTbYV,
  auth_plugin_6DDHMgc34l,
  FBPixel_pfxXiHSWff,
  apollo_client_VTaW5dp4nd,
  changeCountry_q2Al21Mu2F,
  hotjar_pBWYs5JQbk,
  importPlugins_dSrmlJHrsT,
  intercom_tYaoKVt49Q,
  mixpanel_vYbGOFriae,
  msw_6UYrCvzujF,
  navigation_QptjALbk5P,
  setCountry_56dWCNFicO,
  setUser_7ED3DT1SjL,
  userSnap_JoYSL8axbe,
  validationFields_ioW4FysPzn
]